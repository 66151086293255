<template>
    <div class="footer-component">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-sm-8">
                    <div class="fs-3 pb-4">
                       &copy; Copyright {{ currentDate.getFullYear() }} newit
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentDate: new Date
        }
    },
    name: 'FooterComponent',
}
</script>