<template>
  <div class="col-3 col-lg-4" id="flower-component-col">
    <router-link :to="'/catalog#' + hash">
      <div class="card my-3" id="flower-card">
        <div class="row g-0">
          <div class="col-md-8">
            <div class="card-body text-start">
              <h5 class="card-title">{{ title }}</h5>
              <p class="card-text">
                {{ description }}
              </p>
            </div>
          </div>
          <div class="col-md-4 my-auto">
            <img :src="imageUrl" class="img-fluid" id="flower-image" />
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  name: "FlowerRowComponent",
  props: {
    title: String,
    description: String,
    imageUrl: String,
    hash: String
  },
};
</script>

<style>
#flower-card {
  background: none;
  border: none !important;
  border-radius: 0px !important;
}

#flower-image {
  background-image: url(../assets/flower_bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  padding: 0.5em;
}
#flower-component-col {
  background: #87a8d959 !important;
}
#flower-component-col:hover {
  background: #4079cd59 !important;
}

#flower-component-col a {
  color: #535353 !important;
  text-decoration: none !important;
}
</style>
