<template>
  <div class="hello">
    <section id="mobile-first-section" class="d-lg-none">
      <div class="container-fluid ">
        <div class="row justify-content-center">
          <div class="col-8">
            <img src="../assets/ecutext.png" class="img-fluid" />
          </div>
        </div>
        <div>
          <h5 class="card-title fs-3 pt-4 pb-3">{{ $t("home.logo-text") }}</h5>
          <img src="../assets/rererer.png" id="home-blue-flower-mobile" />
        </div>
      </div>
    </section>
    <section id="first-section" class="d-none d-lg-block">
      <div class="container-fluid" id="firs-row">
        <div class="row justify-content-center">
          <div class="col-9" id="home-logo-col">
            <div class="card mx-auto" style="width: 73rem" id="home-logo-card">
              <div class="card-body">
                <div class="row">
                  <div class="col-5" id="home-card-left">
                    <img src="../assets/logo_text.svg" class="card-img-top" />
                    <h5 class="card-title fs-3">{{ $t("home.logo-text") }}</h5>
                  </div>
                  <div class="col-7 my-auto" id="home-card-right">
                    <img src="../assets/rererer.png" id="home-blue-flower" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="second-section">
      <div class="anchor" id="about"></div>
      <div class="container-fluid" id="home-about-us">
        <div class="row justify-content-center">
          <HeadingComponent :title="$t('home.first-heading')" class="col-8 col-lg-2" />
          <div class="col-4 col-lg-9"></div>
        </div>
        <div class="row justify-content-center">
          <div class="col-11">
            <p class="text-start" id="first-text">
              {{ $t("home.first-text") }}
            </p>
          </div>
        </div>
      </div>
      <div class="container-fluid" id="home-catalog">
        <div class="row justify-content-center">
          <HeadingComponent :title="$t('home.second-heading')" class="col-9 col-lg-2" />
          <div class="col-3 col-lg-9"></div>
        </div>
        <div class="col-lg-11 mx-auto">
          <div class="row justify-content-center d-none d-lg-flex">
            <FlowerRowComponent :title="$t('home.catalog.premium')"
              :description="$t('home.catalog.premium-description')" :image-url="require(`../assets/1_home.png`)"
              :hash="'premium'" />
            <FlowerRowComponent :title="$t('home.catalog.dyed')" :description="$t('home.catalog.dyed-description')"
              :image-url="require(`../assets/2_home.png`)" :hash="'dyed'" />
            <FlowerRowComponent :title="$t('home.catalog.group')" :description="$t('home.catalog.group-description')"
              :image-url="require(`../assets/3_home.png`)" :hash="'group'" />
          </div>
        </div>
        <div class="col-lg-11 mx-auto">
          <div class="row justify-content-center d-none d-lg-flex">
            <FlowerRowComponent :title="$t('home.catalog.garden')" :description="$t('home.catalog.garden-description')"
              :image-url="require(`../assets/4_home.png`)" :hash="'garden'" />
            <FlowerRowComponent :title="$t('home.catalog.perpetual')"
              :description="$t('home.catalog.perpetual-description')" :image-url="require(`../assets/5_home.png`)"
              :hash="'perpetual'" />
            <FlowerRowComponent :title="$t('home.catalog.other')" :description="$t('home.catalog.other-description')"
              :image-url="require(`../assets/6_home.png`)" :hash="'other'" />
          </div>
        </div>
        <div class="row d-lg-none justify-content-center px-2">
          <HomeCatalogFlowerComponent />
        </div>
      </div>
    </section>
    <section id="third-section">
      <div class="anchor" id="quality"></div>
      <div class="container-fluid" id="home-quality">
        <div class="row justify-content-center mb-4">
          <HeadingComponent :title="$t('home.third-heading')" class="col-8 col-lg-2" />
          <div class="col-4 col-lg-9"></div>
        </div>
        <div class="row justify-content-center">
          <div class="col-11 col-lg-11" id="quality-card">
            <div class="card" id="flower-card">
              <div class="row">
                <div class="col-md-9 my-auto">
                  <div class="card-body text-start">
                    <p class="card-text">
                      {{ $t("home.third-text") }}
                    </p>
                    <p class="card-text">
                      {{ $t("home.third-text-second") }}
                    </p>
                  </div>
                </div>
                <div class="col-md-3 g-0 d-none d-lg-block">
                  <img src="../assets/quality_flower_new.png" alt="" class="img-fluid imgpositioning" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" id="home-contact">
        <div class="row justify-content-center mb-4">
          <HeadingComponent :title="$t('home.fourth-heading')" class="col-9 col-lg-2" />
          <div class="col-3 col-lg-9"></div>
        </div>
        <div class="col-lg-11 mx-auto">
          <div class="row justify-content-center">
            <div class="col-11 col-lg-4 pt-5 ps-lg-4" id="quality-card">
              <div class="fs-2">{{ $t("home.form.contact-us") }}</div>
              <FormComponent />
            </div>
            <div class="col-lg-4 d-none d-lg-block" id="quality-card">
              <img id="contactusflower" src="../assets/contact_us_flower_new_copy.png" alt="" class="img-fluid" />
            </div>
            <div class="col-11 col-lg-4" id="quality-card">
              <div class="card bg-transparent border-0">
                <div class="card-body">
                  <h2 class="card-title" id="contact-title">
                    {{ $t("home.info.company") }}
                  </h2>
                  <h6 class="card-subtitle fs-4 mb-2 text-muted my-4">
                    <img src="../assets/phone-solid.svg" id="phone-svg" />
                    <a href="tel:+36300723831" id="phone-number">
                      +36 30 072 3831
                    </a>
                  </h6>
                  <h6 class="card-subtitle fs-4 text-muted e-mail">
                    <img src="../assets/at-solid.svg" id="at-svg" />
                    <a href="mailto:info@ecuflower.com" id="e-mail">
                      info@ecuflower.com
                    </a>
                  </h6>
                  <p class="card-text fs-5 text-start mb-4">
                    {{ $t("home.info.to-find") }}
                  </p>
                  <p class="card-text fs-5 text-start my-0">
                    {{ $t("home.info.address") }}
                  </p>
                  <p class="card-text fs-5 text-start my-0">
                    {{ $t("home.info.tax-number") }}: 25814941-2-08
                  </p>
                  <p class="card-text fs-5 text-start my-0">
                    {{ $t("home.info.registration-number") }}: 08 09 031972
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HeadingComponent from "./HeadingComponent.vue";
import FlowerRowComponent from "./FlowerRowComponent.vue";
import FormComponent from "./FormComponent.vue";
import HomeCatalogFlowerComponent from "./HomeCatalogFlowerComponent.vue";
export default {
  name: "HelloWorld",
  components: {
    HeadingComponent,
    FlowerRowComponent,
    FormComponent,
    HomeCatalogFlowerComponent,
  },
};
</script>

<style scoped>
#firs-row {
  margin-top: 10em;
}

#home-flower {
  transform: rotate(-25deg);
}

#second-section {
  background: url("../assets/ivy.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 10%;
}

#first-text {
  background-color: #87a8d959;
  padding-top: 2em;
  padding-right: 1em;
  padding-left: 1em;
  padding-bottom: 2em;
}

#contact-row {
  background-color: #87a8d959 !important;
}

#phone-svg,
#at-svg {
  filter: invert(57%) sepia(17%) saturate(417%) hue-rotate(187deg) brightness(94%) contrast(85%);
  width: 1em;
  margin-right: 1em;
}

#contact-title {
  margin-top: 2em;
  margin-bottom: 2em;
}

#e-mail,
#phone-number {
  color: #535353;
  text-decoration: none;
}

#contact-col {
  padding-right: 2em;
  padding-left: 2em;
}

.card-subtitle.fs-4.text-muted.e-mail {
  margin-top: 1em !important;
  margin-bottom: 2em !important;
}

#home-logo-card {
  background: transparent !important;
  border: none !important;
  display: block !important;
}

/*#home-logo-col {
  background: url("../assets/palms.png");
  background-repeat: no-repeat;
  padding-bottom: 10em;
  background-size: contain;
}*/

.outer-container {
  width: 30em;
  margin: auto !important;
  display: block !important;
}

#home-about-us,
#home-catalog,
#home-quality,
#home-contact {
  margin-bottom: 10em;
}

#quality-card {
  background: #87a8d959 !important;
}

#contactusflower {
  max-height: 70vh;
}

/*#third-section {
  background: url("../assets/palms.png");
  background-repeat: no-repeat;
  background-size: 60%;
}*/

#mobile-home-flower {
  transform: rotate(-25.77deg);
}

#home-blue-flower-mobile {
  height: 13em !important;
}

#home-first-row {
  background: url("../assets/home_blue_flower_bg.png");
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: 100% 5%;
}

#home-blue-flower {
  height: 27em !important;
}

#home-card-left {
  opacity: 0.6;
  padding-left: 0;
}

#flower-component-col a {
  color: #535353 !important;
  text-decoration: none !important;
}

#home-card-right {
  margin-left: -5em !important;
}

.imgpositioning {
  width: 100% !important;
}

@media only screen and (max-device-width: 812px) {

  #home-about-us,
  #home-catalog,
  #home-quality,
  #home-contact {
    margin-bottom: 3em;
  }

  /*#third-section {
    background: url("../assets/palms.png");
    background-repeat: no-repeat;
    background-size: 100%;
  }*/
}
</style>
