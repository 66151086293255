<template>
  <form>
    <input
      type="text"
      name="name"
      id="name"
      class="form-control mb-4"
      required="required"
      :data-error="$t('home.form.required')"
      :placeholder="$t('home.form.name')"
      :v-model="name"
    />
    <input
      type="email"
      name="email"
      id="email"
      class="form-control mb-4"
      required="required"
      :data-error="$t('home.form.required')"
      :placeholder="$t('home.form.email')"
      :v-model="email"
    />
    <textarea
      name="message"
      id="message"
      class="form-control mb-4"
      required="required"
      :placeholder="$t('home.form.message')"
      :data-error="$t('home.form.required')"
      :v-model="message"
      cols="30"
      rows="10"
    ></textarea>
    <figcaption class="text-start blockquote-footer">
      {{ $t("home.form.requireds") }}
    </figcaption>
    <button 
      type="submit" 
      value="Send" 
      class="btn fs-4"
    >
      {{ $t("home.form.send") }}
    </button>
  </form>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
  name: "FormComponent",
  data () {
    return {
      name: '',
      email: '',
      message: ''
    }
  },
  methods: {
    sendEmail(e) {
      try {
        emailjs.sendForm('service_dooihy1', 'template_97whmma', e.target,
        'YWAHv1h6E0Z02L_IO', {
          name: this.name,
          email: this.email,
          message: this.message
        })

      } catch(error) {
          console.log({error})
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.message = ''
    },
  }
};
</script>

<style>
.form-control {
  background: transparent !important;
  border: none !important;
  border-bottom: 4px solid #4079cd8c !important;
  border-radius: 0 !important;
}

.form-control:focus {
  background: transparent !important;
  border-color: #4079cd8c !important;
}

.blockquote-footer::before {
  content: "*" !important;
}

.btn {
    color: #535353 !important;
}
</style>
