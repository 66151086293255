<template>
  <div class="col-6 g-1" v-for="category in categories" :key="category.id">
    <router-link :to="'/catalog#' + category.name">
      <div class="card" id="home-flower-card">
        <div class="card-body">
          <div class="card-title" id="home-category-title">
            {{ $t("home.catalog." + category.name) }}
          </div>
          <div class="home-image-outer">
            <img
              class="img-fluid"
              :src="require('../assets/' + category.id + '_home.png')"
              alt=""
              id="home-rose-image"
            />
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import CategoriesArray from "../data/categories.json";
export default {
  name: "HomeCatalogFlowerComponent",
  data() {
    return {
      categories: CategoriesArray,
    };
  },
};
</script>

<style scoped>
#home-flower-card {
  border: none !important;
  background: #87a8d959 !important;
  border-radius: 0px !important;
}

.home-image-outer {
  background-image: url("../assets/catalog_rose_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  display: flex;
  justify-content: center;
  min-height: 10em !important;
}

#home-rose-image {
  margin: auto !important;
  display: block !important;
  width: 8em;
}

#home-category-title {
  font-size: 1.1em !important;
}

a {
  color: #535353 !important;
  text-decoration: none !important;
}
</style>
