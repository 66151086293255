<template>
  <div :class="$attrs.class">
    <div class="outer pb-2 my-4" id="title">
      <div class="inner text-start">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeadingComponent",
  props: {
    title: String,
  },
};
</script>

<style>
#title {
  font-size: 3.5em;
}

.outer {
  margin-left: 0.5em;
}

.inner {
  margin-left: -0.5em;
}
@media only screen 
  and (max-device-width: 812px) 
{ 
    #title {
      font-size: 2.5em;
    }
}
</style>
